$.fn.lib_flickity = function () {
    let selector = $(this);
    if (!html.hasClass("ie9")) {
        $.importScript(cdnjs.flickity, function() {
            cssLoaded(function () {
                selector.each(function(){
                    let elm = $(this);
                    let options = elm.data("lib-flickity");
                    let items = elm.children().length;
                    let nav = elm.closest("[data-ref-flickity]").find("[data-lib-flickity-nav]");
                    let dots = elm.closest("[data-ref-flickity]").find("[data-lib-flickity-dots]");
                    let counter = elm.closest("[data-ref-flickity]").find("[data-lib-flickity-counter]");

                    if(typeof options["minItems"] !== "undefined") {
                        if(items <= options["minItems"]["d"]) {
                            options["watchCSS"] = true;
                            elm.addClass("flickity-disabled-d")
                        }
                        if(items <= options["minItems"]["t"]) {
                            options["watchCSS"] = true;
                            elm.addClass("flickity-disabled-t")
                        }
                        if(items <= options["minItems"]["m"]) {
                            options["watchCSS"] = true;
                            elm.addClass("flickity-disabled-m")
                        }
                    }

                    let slider = $(this).flickity({
                        cellAlign: (typeof options["cellAlign"] !== "undefined") ? options["cellAlign"] : "left",
                        draggable: (typeof options["draggable"] !== "undefined") ? options["draggable"] : ">1",
                        groupCells: (typeof options["groupCells"] !== "undefined") ? options["groupCells"] : false,
                        setGallerySize: true,
                        autoPlay: options["autoPlay"],
                        pageDots: true,
                        wrapAround: (typeof options["wrapAround"] !== "undefined") ? options["wrapAround"] : true,
                        contain: true,
                        adaptiveHeight: false,
                        pauseAutoPlayOnHover: true,
                        prevNextButtons: !nav.length,
                        percentPosition: (typeof options["percentPosition"] !== "undefined") ? options["percentPosition"] : false,
                        watchCSS: (typeof options["watchCSS"] !== "undefined") ? options["watchCSS"] : false,
                    });

                    let flkty = slider.data('flickity');

                    if(typeof flkty.slides !== "undefined" && flkty.slides.length === 1) {
                        nav.remove();
                    }

                    if (typeof options["flex"] !== "undefined") {
                        elm.addClass("flickity-flex");

                        win.on("resize",debounce(function () {
                            elm.removeClass("flickity-flex");
                            elm.flickity("resize");
                            elm.addClass("flickity-flex");
                        },250));
                    }

                    if (typeof options["parallax"] !== "undefined") {
                        let carousel_img = slider.find('.part_ui_image'),
                            docStyle = document.documentElement.style,
                            transformProp = typeof docStyle.transform === 'string' ? 'transform' : 'WebkitTransform';

                        slider.on('scroll.flickity', function () {
                            flkty.slides.forEach(function (slide, i) {
                                let img = carousel_img[i],
                                    x = (slide.target + flkty.x) * -1;

                                img.style[transformProp] = 'translateX(' + Math.round(x) + 'px)';
                            });
                        });
                    }

                    if (nav.length) {
                        nav.on("click", function(){
                            let direction = $(this).data("lib-flickity-nav");
                            if (direction === "prev") {
                                slider.flickity('previous');
                            } else {
                                slider.flickity('next');
                            }
                        });

                        if(options["wrapAround"] === false) {
                            slider.on('change.flickity',function (event, index) {
                                nav.removeAttr("disabled");
                                if(index === flkty.slides.length - 1) {
                                    nav.each(function () {
                                        if($(this).is("[data-lib-flickity-nav=\"next\"]")) {
                                            $(this).attr("disabled","disabled");
                                        }
                                    })
                                }
                                if(index === 0) {
                                    nav.each(function () {
                                        if($(this).is("[data-lib-flickity-nav=\"prev\"]")) {
                                            $(this).attr("disabled","disabled");
                                        }
                                    })
                                }
                            });
                        }
                    }

                    if (dots.length) {
                        elm.find(".flickity-page-dots").appendTo(dots);
                    }

                    if(counter.length) {
                        slider.on('change.flickity',function (event, index) {
                            counter.text(`${index+1} I ${items}`);
                        });
                    }
                });
            });
        });
    }
};
