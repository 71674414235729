$.lui("comp",".comp_list_breweries",(selector)=>{
    let nav = selector[0].querySelector(".elm_comp_nav");
    nav.addEventListener("click",(e)=>{
        let el = e.target;
        if(el.tagName !== "A") return;
        if(el.classList.contains("state--active")) return;
        nav.querySelector(".state--active").classList.remove("state--active");
        el.classList.add("state--active");

        $(nav).animate({
            scrollLeft: el.offsetLeft - 48
        });
    });
});