$.lui("comp",".comp_countdown",(selector)=> {
    let elm_body = selector[0].querySelector(".wrp_comp_body"),
        due_date = new Date(elm_body.dataset.date);

    if(new Date().getTime() > due_date.getTime()) {
        // console.log("already started");
        return;
    }

    let elm_days = elm_body.querySelector(".is--days"),
        elm_hours = elm_body.querySelector(".is--hours"),
        elm_minutes = elm_body.querySelector(".is--minutes"),
        elm_seconds = elm_body.querySelector(".is--seconds");

    $.importScript(cdnjs.countdown,()=>{
        let timer = countdown((t)=> {
            elm_days.querySelector(".elm_value").innerHTML = t.days.toString();
            elm_hours.querySelector(".elm_value").innerHTML = `${(t.hours.toString().length === 1) ? "0" : ""}` + t.hours.toString();
            elm_minutes.querySelector(".elm_value").innerHTML = `${(t.minutes.toString().length === 1) ? "0" : ""}` + t.minutes.toString();
            elm_seconds.querySelector(".elm_value").innerHTML = `${(t.seconds.toString().length === 1) ? "0" : ""}` + t.seconds.toString();
        }, due_date , countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS);
    });
});