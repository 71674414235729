$.lui("layout","#layout_header", function(selector) {
    let header = selector.find(".wrp_header_body");
    let nav_toggle;

    if (! $("#layout_nav").length) {
        selector.after('<nav id="layout_nav"><div class="wrp_nav_content"></div></nav>');

        let layout_nav = $(document).find("#layout_nav"),
            nav = header.find(".elm_header_nav").clone(),
            social = header.find(".elm_header_social").clone(),
            cta = header.find(".col--cta").find(".part_ui_btn").clone();
        layout_nav.find(".wrp_nav_content").append(nav);
        layout_nav.find(".wrp_nav_content").append(cta);
        layout_nav.find(".wrp_nav_content").append(social);

        nav_toggle = document.querySelector("#layout_header").querySelector("[data-nav-toggle]");

        nav_toggle.addEventListener("click",(e)=>{
            e.preventDefault();
            e.stopPropagation();
            document.querySelector("body").classList.toggle("nav--visible");
        });
    }

    let flash = selector.find(".elm_header_flash");

    $.importScript(cdnjs.headroom, function(){
        let headroom = new Headroom(selector[0], {
            offset: 64,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            },
            onTop : function() {
                if(flash.length) {
                    flash.slideDown(300);
                }
            },
            onNotTop : function() {
                if(flash.length) {
                    flash.slideUp(300);
                }
            },
        });
        headroom.init();
    });

    selector.on("click", `.elm_header_flash [data-click="close"]`, function(){
        let elm = $(this);
        elm.closest(".elm_header_flash").slideUp(300);
        setTimeout(function() {
            elm.closest(".elm_header_flash").remove();
            selector.removeClass("has--flash")
        },300);
    });
});
