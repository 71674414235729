$.lui("comp",".comp_faq",(selector)=>{
    let toggle = selector[0].querySelector("[data-more-toggle"),
        target = selector[0].querySelector("[data-more-target]"),
        t_lang = JSON.parse(toggle.dataset["moreToggle"]),
        t_text = toggle.querySelector(".elm_toggle_text");

    toggle.addEventListener("click",(e)=>{
        e.preventDefault();
        if(toggle.classList.contains("state--active")) {
            target.style.height = 0 + "px";
            toggle.classList.remove("state--active");
            t_text.innerHTML = t_lang["more"];
            selector.anchor_anim(500);
        }
        else {
            target.style.height = target.scrollHeight + "px";
            toggle.classList.add("state--active");
            t_text.innerHTML = t_lang["less"];
        }
    });
});