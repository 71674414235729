$.lui("comp",".comp_gallery_video",(selector)=>{
    let nav = selector[0].querySelector(".elm_comp_nav");
    nav.addEventListener("click",(e)=>{
        let el = e.target;
        if(el.tagName !== "A") return;
        if(el.classList.contains("state--active")) return;
        nav.querySelector(".state--active").classList.remove("state--active");
        el.classList.add("state--active");

        $(nav).animate({
            scrollLeft: el.offsetLeft - 48
        });
    });

    let nav_slider = selector[0].querySelector(".elm_comp_carousel_nav");
    let nav_slider_items = nav_slider.querySelectorAll(".elm_carousel_item");

    nav_slider.addEventListener("click",(e)=>{
        let el = e.target.closest(".elm_carousel_item");
        if(el === null) return;
        for (let i = 0; i < nav_slider_items.length; i++) {
            if(nav_slider_items[i] === el) {
                let flkty = new Flickity(selector[0].querySelector(".elm_comp_carousel_main"));
                flkty.select(i);
                break;
            }
        }
    });
});