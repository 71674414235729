$.lui("part",".part_form_contact",function (selector) {
    selector.on("change","[name=\"power\"]",function () {
        let el = $(this);
        let value = el.val();
        let fieldset = el.closest('fieldset');

        if(value === 'none') {
            fieldset.find('[data-target="powerWattage"]').slideUp(300)
            fieldset.find('[data-target="powerNoneNotice"]').slideDown(300)
            fieldset.find('[data-target="powerWattage"]').find('input').removeAttr('required')
        } else {
            fieldset.find('[data-target="powerWattage"]').slideDown(300)
            fieldset.find('[data-target="powerNoneNotice"]').slideUp(300)
            fieldset.find('[data-target="powerWattage"]').find('input').attr('required',true)
        }
    });

    selector.on("change","[name=\"placesize\"]",function () {
        let el = $(this);
        let value = el.val();
        let fieldset = el.closest('fieldset');

        if(value === 'other') {
            fieldset.find('[data-target="placeSizeCustom"]').slideDown(300)
            fieldset.find('[data-target="placeSizeCustom"]').find('input').attr('required',true)
        } else {
            fieldset.find('[data-target="placeSizeCustom"]').slideUp(300)
            fieldset.find('[data-target="placeSizeCustom"]').find('input').removeAttr('required')
        }
    });
});
